import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../constants'

export const styles = makeStyles(theme => ({
  section: {
    paddingBottom: 120,
    [`& nav.MuiBreadcrumbs-root`]: {
      marginBottom: -10,
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        marginBottom: 20,
      },
    },
    [`& li.MuiBreadcrumbs-li a`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      color: COLORS.black,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      cursor: 'pointer',
      fontFamily: 'Graphik Light',
    },
    [`& li.MuiBreadcrumbs-separator`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
      marginTop: 5,
    },
    [`& li.MuiBreadcrumbs-li p`]: {
      height: 27.43,
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '31px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
    },
  },
  TopTitle: {
    paddingTop: 30,
    textAlign: 'center',
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'space-around',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      maxWidth: 600,
      margin: 'auto',
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      maxWidth: 375,
    },
  },
  secondRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 40,
    paddingBottom: 50,
    flexWrap: 'wrap',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      paddingTop: 0,
      paddingBottom: 30,
      flexDirection: 'column',
      maxWidth: 600,
      margin: 'auto',
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      maxWidth: 375,
    },
  },
  buttonStyles: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '0.75rem',
    maxWidth: 343,
    '& > img': {
      filter: 'invert(1)',
    },
  },
  centerText: {
    textAlign: 'center',
  },
  gridItem: {
    textAlign: 'center',
    width: '100%',
  },
  videoSectionTitle: {
    marginBottom: 20,
  },
  videoSectionContent: {
    lineHeight: '23px',
  },
  circle2: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      marginLeft: 200,
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      marginLeft: 77,
    },
  },
  circle4: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      marginLeft: 200,
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      marginLeft: 87,
    },
  },
  joinCircle: {
    width: '100%',
    maxWidth: 450,
    textAlign: 'center',
  },
}))
